<template>
  <d-b-table
    title="Other Entries (from Questionnaire)"
    :load-path="sever_address"
    :delete-path="sever_address"
    :show-index="false"
    id-column="id"
    :columns="{'Text':'text'}"
    />
</template>
<script>

import DBTable from "@/components/DBTable";

export default {
  data(){
    return{
      sever_address: '/other/'
    }
  },
  methods:{
  },
  async mounted () {
  },
  components:{
    DBTable
  }
}
</script>
